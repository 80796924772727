// KEEP IN SYNC with emailreports/datePresets.py

import {
  endOfMonth,
  endOfToday,
  endOfYesterday,
  setMilliseconds,
  startOfMonth,
  startOfToday,
  startOfYesterday,
  subDays,
  subHours,
  subMilliseconds,
  subMonths,
} from 'date-fns'

function makeRangePresets() {
  const datePresets = []

  // Yesterday
  datePresets.push({
    value: 'yesterday',
    text: 'mobilewall-report-range-yesterday',
    start: startOfYesterday(),
    end: endOfYesterday(),
  })

  // Today
  datePresets.push({
    value: 'today',
    text: 'mobilewall-report-range-today',
    start: startOfToday(),
    end: endOfToday(),
  })

  // Last 2 hours
  datePresets.push({
    value: 'last2hours',
    text: 'mobilewall-report-range-last-2-hours',
    start: subHours(new Date(), 2),
    end: new Date(),
  })

  // Last 12 hours
  datePresets.push({
    value: 'last12hours',
    text: 'mobilewall-report-range-last-12-hours',
    start: subHours(new Date(), 12),
    end: new Date(),
  })

  // Last 7 days
  datePresets.push({
    value: 'last7days',
    text: 'mobilewall-report-range-last-7-days',
    start: subDays(startOfToday(), 7),
    end: endOfYesterday(),
  })

  // Last 30 days
  datePresets.push({
    value: 'last30days',
    text: 'mobilewall-report-range-last-30-days',
    start: subMonths(startOfYesterday(), 1),
    end: endOfYesterday(),
  })

  // Last Month
  datePresets.push({
    value: 'lastMonth',
    text: 'mobilewall-report-range-last-month',
    start: startOfMonth(subMonths(new Date(), 1)),
    end: endOfMonth(subMonths(new Date(), 1)),
  })

  // This Month
  datePresets.push({
    value: 'thisMonth',
    text: 'mobilewall-report-range-this-month',
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  })

  // This Year
  // datePresets.push({
  //   value: 'thisYear',
  //   text: 'mobilewall-report-range-this-year',
  //   start: startOfYear(new Date()),
  //   end: endOfYear(new Date()),
  // })

  // Placeholder
  datePresets.push({
    value: null,
    text: 'mobilewall-report-filter-placeholder-date-range',
  })

  // Enforce milliseconds values. We let some margin inside ranges.
  datePresets.forEach((pre) => {
    if (pre.start) pre.start = setMilliseconds(pre.start, 100)
    if (pre.end) pre.end = subMilliseconds(setMilliseconds(pre.end, 0), 100)
  })

  return datePresets
}
const datePresets = makeRangePresets()

export { datePresets }
